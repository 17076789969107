import React from 'react'
import { SvgIconProps } from '@mui/material'
import {
  ArrowDropDownRounded,
  ArrowDropUpRounded,
  CalendarTodayRounded,
  CompareArrowsRounded,
  DesktopWindowsRounded,
  DoneAllRounded,
  EventAvailableRounded,
  EventNoteRounded,
  HomeRounded,
  LocalHospitalRounded,
  MenuOpenRounded,
  MenuRounded,
  PersonRounded,
  QueryStatsRounded,
  SearchRounded,
  SettingsRounded,
  ViewModuleRounded,
  Payments,
} from '@mui/icons-material'

export const icons = {
  arrowDropUp: ArrowDropUpRounded,
  arrowDropDown: ArrowDropDownRounded,
  menuOpen: MenuOpenRounded,
  menu: MenuRounded,
  desktop: DesktopWindowsRounded,
  home: HomeRounded,
  user: PersonRounded,
  modules: ViewModuleRounded,
  chartbar: QueryStatsRounded,
  calendar: CalendarTodayRounded,
  search: SearchRounded,
  calendarEvents: EventNoteRounded,
  doneAll: DoneAllRounded,
  pharmacy: LocalHospitalRounded,
  settings: SettingsRounded,
  reserve: EventAvailableRounded,
  compare: CompareArrowsRounded,
  payments: Payments,
}

export type IconsKeys = keyof typeof icons

export interface IconProps extends SvgIconProps {
  icon: keyof typeof icons
}
const Icon = ({
  icon,
  color = `inherit`,
  ...props
}: IconProps): JSX.Element => {
  const IconComponent = icons[icon]

  return <IconComponent color={color} {...props} />
}
export default Icon
